body {
   margin: 0;
   font-family: 'Lato', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.font {
   &-italic {
      font-style: italic;
   }

   &-medium {
      font-weight: 500;
   }

   &-bold {
      font-weight: bold;
   }
}
