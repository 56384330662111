article {
   .article-title {
      margin-bottom: 2rem;
      text-align: center;
   }

   .article-content {
      margin: 0 auto;
      width: 65%;
      margin-top: 2rem;
   }
}
