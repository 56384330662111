$active-font-weight: 500;
$nav-link-transition: 0.5s;
$start-end-spacing: 2rem;
$brand-font-size: 18px;

$light-blue: #66b9f0;

.navbar {
   background: #ebebeb !important;

   .navbar-toggler {
      margin-right: $start-end-spacing;
   }

   .navbar-brand {
      margin-left: $start-end-spacing;
      font-size: $brand-font-size;
      font-weight: $active-font-weight;
      color: #515151 !important;

      &:hover {
         color: $light-blue !important;
         transition: 0.75s;
      }
   }

   .navbar-nav {
      width: 100%;

      .nav-item {
         position: relative;
         transition: $nav-link-transition;
         margin-right: $start-end-spacing;

         a {
            padding-left: 0 !important;
            padding-right: 0 !important;
         }

         ::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 3px;
            top: 92%;
            left: 0;
            background: #1f1f1f;
            transition: transform $nav-link-transition;
            transform: scaleX(0);
            transform-origin: right;
         }

         &:hover {
            font-weight: $active-font-weight;
            ::after {
               transform: scaleX(1);
               transform-origin: left;
            }
         }

         .active {
            font-weight: $active-font-weight;
            border-bottom: 3px solid black;
            padding-bottom: 0.3rem;
         }
      }
   }
}

@media (min-width: 268px) {
   .navbar-nav {
      align-items: center;
   }
}
