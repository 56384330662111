.img-container {
   position: relative;

   figure {
      margin: auto;
   }

   figcaption {
      font-style: italic;
      text-align: center;
      margin-top: 0px;
      font-size: 14px;
   }

   img {
      display: block;
      margin-left: auto;
      margin-right: auto;
   }

   &:hover .img-link {
      visibility: visible;
      opacity: 1;
   }

   .img-link {
      position: absolute;
      visibility: hidden;
      color: rgb(231, 231, 231);
      text-decoration: underline;
      bottom: 15px;
      left: 260px;
      opacity: 0;
      transition: opacity 0.5s, visibility 0.5s;
   }
}
