$large: 950px;
$small: 300px;

.cv {
   margin: 0 auto;
   margin-top: 5%;
   width: 60%;

   .title {
      margin-bottom: 2rem;
      text-align: center;
   }

   .key-skills {
      font-weight: bold;
      font-size: 14px;

      @media screen and (min-width: $large) {
         margin-left: 48px;
      }
   }

   .section-title {
      color: #5f5f5f;
      font-weight: bold;
      font-size: 18px;
   }

   .section {
      margin-top: 20px;
      .section-item {
         margin-top: 20px;
         margin-bottom: 10px;

         .section-item-timeline {
            font-weight: bold;
            font-size: 12px;

            @media screen and (min-width: $small) {
               margin-bottom: 10px;
            }
         }

         .section-item-title {
            float: left;
            font-size: 14px;
            font-weight: bold;
         }

         .section-item-desc {
            margin-top: 5px;
            font-size: 14px;

            @media screen and (min-width: $large) {
               margin-left: 50px;
            }
         }

         .section-item-list {
            list-style: disc;

            margin-top: 10px;
            font-size: 14px;

            @media screen and (min-width: $large) {
               margin-left: 35px;
            }
         }

         .section-item-title {
            @media screen and (min-width: $large) {
               margin-left: 50px;
            }
         }
      }
   }
}
