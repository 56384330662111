.about {
   img {
      width: 350px !important;
      height: 350px !important;
      margin: auto;
      margin-top: 15px;
      display: block;
      filter: grayscale(45%);
   }

   article {
      .article-title {
         margin-top: 15px;
         margin-bottom: -15px !important;
      }
      .article-content {
         // margin-top: 5px;
         // margin-bottom: 5px !important;
      }
   }
}
