.breadcrumb-nav {
   color: gray;
   padding-top: 15px;
   padding-bottom: 15px;
   background-color: gray;

   &-caret {
      margin: 0 10px;
   }

   &-project-name {
      font-weight: bold;
   }

   a {
      color: gray;
      text-decoration: none;

      &:hover {
         text-decoration: underline;
      }
   }
}

.performance-info {
   ul {
      list-style-type: none;
   }

   ul li:before {
      content: '\2014';
      position: absolute;
      margin-left: -20px;
   }
}
