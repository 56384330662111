.card-grid {
   .col {
      margin-top: 40px;
   }

   a {
      text-decoration: none;
   }
}

.card {
   margin: 0 auto;
   border: none;
   box-shadow: 0 0 11px rgba(149, 149, 149, 0.333);

   &:hover {
      cursor: pointer;
      transition: 0.333s;
      box-shadow: 0 0 11px rgba(149, 149, 149, 0.5);
   }

   .card-body {
      font-weight: bolder;
      color: #555555;
   }

   .card-img-top {
      filter: opacity(0.85);
   }

   .card-text {
      text-align: center;
      font-size: 12px;
   }
}
